// eslint-disable-next-line @typescript-eslint/no-namespace
import {
  BandmanagerRestApiBand, BandmanagerRestApiBandStatsDto, BandmanagerRestApiBankaccount,
  BandmanagerRestApiUniform
} from "@digitale-menschen/bandmanager-rest-api";

export namespace BandStateActions {

  export class SetBand {
    static readonly type = '[Band] SetBand';

    constructor(public band: BandmanagerRestApiBand) {
    }
  }

  export class SetBankAccount {
    static readonly type = '[Band] SetBankAccount';

    constructor(public bankAccount: BandmanagerRestApiBankaccount) {
    }
  }

  export class SetStats {
    static readonly type = '[Band] SetStats';

    constructor(public stats: BandmanagerRestApiBandStatsDto) {
    }
  }

  export class SetUniform {
    static readonly type = '[Band] SetUniform';

    constructor(public uniform: BandmanagerRestApiUniform) {
    }
  }
}
